import React, { Suspense, useEffect } from "react";
import { AppBar, Button, Grid, Hidden, Box, Toolbar, Container, Avatar, SpeedDial, SpeedDialIcon } from "@mui/material";

import Navbar from "./Navbar";

import EntryEditor from "../EntryEditor";

import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import Footer from "./Footer";
import EventsView from "./EventsView";
import SettingsView from "./SettingsView";
import MantraPopper from "./MantraPopper";

const InsightsController = React.lazy(() => import("./InsightsController"));
const Dashboard = () => {
  const uid = useSelector((state) => state.uid);
  const navigate = useNavigate();
  useEffect(() => {
    if (!uid) {
      navigate("/");
    }
  }, [uid]);
  if (!uid) return "";

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" spacing={0} sx={{ pl: 1, pr: 1 }}>
        <Toolbar sx={{ maxHeight: "28px" }} variant="dense" />
        <Grid item xs={12}>
          <Routes>
            <Route path="settings" element={<SettingsView />} />
            <Route
              path="insights"
              element={
                <Suspense fallback={<>Loading</>}>
                  <InsightsController />
                </Suspense>
              }
            />

            <Route index element={<EventsView />} />
          </Routes>
        </Grid>

        <EntryEditor />
      </Container>
      <MantraPopper />

      <Footer />
    </>
  );
};

export default Dashboard;
