import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

import * as firebase from "../../../service/firestore";
import * as actionTypes from "../../../store/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Hidden, Menu, MenuItem, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import SWUpdate from "../../SWUpdate";
import MenuIcon from "@mui/icons-material/Menu";
export default function ButtonAppBar() {
  const dispatch = useDispatch();
  const photoURL = useSelector((state) => state.photoURL);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const signOutHandler = () => {
    firebase
      .signOut()
      .then((resp) => {
        dispatch({ type: actionTypes.LOGOUT });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const open = Boolean(anchorEl);
  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLeftMenuToggle = () => {
    dispatch({ type: actionTypes.TOGGLE_LEFT_MENU });
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense" sx={{ maxHeight: "32px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleLeftMenuToggle}
            edge="start"
            sx={{
              marginRight: 1,
              ...(open && { display: "none" }),
              display: { xs: "none", md: "block" },
            }}
            className="tour-step-5"
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/dashboard"
            className="tour-step-4"
          >
            <img src={`${process.env.PUBLIC_URL}/images/favicon-32x32.png`} height="40" alt="Logo" />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}>
            TopPriorityToday
          </Typography>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", display: { xs: "block", md: "none" } }}
          >
            TPT
          </Typography>

          <SWUpdate />
          {/* right side menu */}
          <Avatar src={photoURL} onClick={handleOpenUserMenu} sx={{ cursor: "pointer" }} className="tour-step-3" />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ marginTop: 1 }}
          >
            <MenuItem component={Link} to="/dashboard/settings" onClick={() => setAnchorEl(null)}>
              Settings
            </MenuItem>
            <MenuItem onClick={signOutHandler}>Sign Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
