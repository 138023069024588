import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const SWUpdate = () => {
  const updateAvilable = useSelector((state) => state.updateAvilable);
  const swReg = useSelector((state) => state.swReg);

  const pushUpdate = () => {
    console.log("Pushing Update");
    const registrationWaiting = swReg.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };
  return <>{updateAvilable && <Button onClick={pushUpdate}>Update</Button>}</>;
};

export default SWUpdate;
