import { CircularProgress, Grid } from "@mui/material";
import React from "react";

const CalendarTableSkeleton = () => {
  return (
    <Grid container direction="column" justifyContent="center" sx={{ height: "100vh" }}>
      <Grid container item direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default CalendarTableSkeleton;
