import { Card, Grid, Paper, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actionTypes";
import { green, grey, red } from "@mui/material/colors";
import HabitDot from "../../HabitDot";

const FeedEntry = (props) => {
  const dispatch = useDispatch();
  const isoDate = props.entry.date.toISODate();
  const cellStyle = {
    background: grey[700],
    padding: 1,
  };

  if (props.entry.complete) {
    cellStyle.background = green[400];
  }
  if (
    props.entry.text.length > 0 &&
    !props.entry.complete &&
    props.entry.date.toISODate() < DateTime.now().toISODate()
  ) {
    cellStyle.background = red[300];
  }
  const showTapToEdit =
    props.entry.date.toISODate() > DateTime.now().plus({ days: -2 }).toISODate() && props.entry.text.length < 1;

  let dateStr = props.entry.date.toLocaleString(DateTime.DATE_HUGE);
  if (isoDate === DateTime.now().toISODate()) {
    dateStr = "Today";
  } else if (isoDate === DateTime.now().plus({ days: -1 }).toISODate()) {
    dateStr = "Yesterday";
  } else if (isoDate === DateTime.now().plus({ days: 1 }).toISODate()) {
    dateStr = "Tomorrow";
    if (!props.entry.complete) {
      cellStyle.background = grey[800];
    }
  }
  return (
    <Grid item>
      <Card
        onClick={() => dispatch({ type: actionTypes.OPEN_ENTRY_EDITOR, payload: { entry: props.entry } })}
        sx={cellStyle}
        className={isoDate === DateTime.now().toISODate() ? "tour-step-2" : ""}
      >
        <Grid container wrap="nowrap">
          <Grid item flexGrow={1}>
            <Typography variant="subtitle2">{dateStr}</Typography>
            <Typography>{props.entry.text}</Typography>
            {showTapToEdit && <Typography textAlign={"center"}>Tap To Edit</Typography>}
          </Grid>
          <Grid item sx={{ width: "10px", mr: "5px" }} container direction="column" justifyContent="center">
            {Object.keys(props.habits).map((k) => (
              <Grid item key={k} sx={{ mb: "5px" }}>
                <HabitDot
                  color={props.habits[k].color}
                  checked={props.entry.habits.includes(k)}
                  width={12}
                  height={12}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default FeedEntry;
