import { Alert, Button, Grid, Paper, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MantraSettings = () => {
  const stateMantra = useSelector((state) => state.config.mantra);
  const [mantra, setMantra] = useState(stateMantra);
  const [showSave, setShowSave] = useState(false);
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch({ type: "UPDATE_USER_CONFIG", payload: { mantra: mantra } });
    setShowSave(true);
  };
  return (
    <Paper sx={{ border: "1px solid #e8e8e8", padding: 2, width: "100%", maxWidth: "md", m: 1 }}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sx={{ ml: 2 }}>
          <Typography variant="h5">Mantra</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={2}
            value={mantra}
            onChange={(e) => setMantra(e.target.value)}
            placeholder="Your detailed, consise and motivating phrase"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>
      <Snackbar
        open={showSave}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setShowSave(false)}
        autoHideDuration={3000}
      >
        <Alert color="success">Mantra saved.</Alert>
      </Snackbar>
    </Paper>
  );
};

export default MantraSettings;
