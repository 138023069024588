import { Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { useMemo } from "react";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell, Text, Label } from "recharts";

const initData = {
  complete: 0,
  inComplete: 0,
  missed: 0,
  possible: 0,
};

const TPTGauge = (props) => {
  const COLORS = [green[200], red[200], "#e8e8e8"];

  const data = [
    { name: "Complete", value: props.data.complete },
    { name: "InComplete", value: props.data.inComplete },
    { name: "Missed", value: props.data.missed },
  ];
  const perComplete = props.data.possible === 0 ? "--" : Math.round((props.data.complete / props.data.possible) * 100);
  const perSet =
    props.data.possible === 0
      ? "--"
      : Math.round(((props.data.complete + props.data.inComplete) / props.data.possible) * 100); //percent of days filled in
  return (
    <>
      <ResponsiveContainer width="100%" height={110}>
        <PieChart width={400} height={110}>
          <Pie
            data={data}
            cx="50%"
            cy="98%"
            startAngle={180}
            endAngle={0}
            innerRadius={75}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label position="center" offset={5} fill="#ffffff" value={`${perComplete}%`} />
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <Typography textAlign="center" sx={{ m: "0 auto" }} variant="subtitle2">
        {`${perSet}%`} set
      </Typography>
    </>
  );
};

TPTGauge.defaultProps = {
  data: initData,
};
export default React.memo(TPTGauge);
