import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

import React from "react";
import DesktopView from "../../DesktopView";
import MobileView from "../../MobileView";

const EventsList = () => {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const View = () => (mdAndUp ? <DesktopView /> : <MobileView />);

  return <>{<View />}</>;
};

export default EventsList;
