import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const openLeftMenu = useSelector((state) => state.leftMenuOpen);
  const ml = openLeftMenu ? "240px" : 0;
  return (
    <>
      <Grid container sx={{ backgroundColor: grey[800], mt: 5, p: 1, pl: ml, pr: ml }}>
        <Grid container item maxWidth="xl" justifyContent="space-between" sx={{ margin: "0 10px" }}>
          <Grid item>&copy; {DateTime.now().year}</Grid>
          <Grid item>{process.env.REACT_APP_VERSION}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
