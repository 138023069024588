import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import DataClone from "./DataClone";
import HabitSettings from "./HabitSettings";
import MantraSettings from "./MantraSettings";
const Settings = () => {
  return (
    <>
      <Typography align="center" variant="h4">
        Settings
      </Typography>

      <hr></hr>
      <Grid container justifyContent="space-around" direction="column" spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} container direction="row" justifyContent="center">
          <HabitSettings />
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="center">
          <MantraSettings />
        </Grid>
      </Grid>
      <Typography></Typography>
    </>
  );
};

export default Settings;
