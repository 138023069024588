import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import InsightsPanel from "../../Dashboard/InsightsPanel";

const MobileInsights = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return isLoading ? (
    <Grid container item direction="row" justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <InsightsPanel showMonthSelector />
    </>
  );
};

export default MobileInsights;
