import * as actionTypes from "./actionTypes";
import { DateTime } from "luxon";
const init = {
  email: "",
  uid: "",
  photoURL: null,
  isLoadingEntries: false,
  entries: [],
  stats: {},
  config: {},
  entryDates: { start: DateTime.now(), end: DateTime.now() },
  entryEditorOpen: false,
  activeEntry: {},
  habits: {},
  selectedMonth: DateTime.now().startOf("month"),
  updateAvailable: false,
  swReg: null,
  leftMenuOpen: false,
};
const reducer = (state = init, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return { ...state, email: action.payload.email, uid: action.payload.uid, photoURL: action.payload.photoURL };
    case actionTypes.LOGOUT:
      return init;
    case actionTypes.SET_ENTRIES:
      return { ...state, entries: action.payload.entries, entryDates: action.payload.entryDates };
    case actionTypes.APPEND_ENTIRES:
      return {
        ...state,
        entries: { ...state.entries, ...action.payload.entries },
        entryDates: action.payload.entryDates,
      };
    case actionTypes.SET_HABITS:
      return { ...state, habits: action.payload };

    case actionTypes.OPEN_ENTRY_EDITOR:
      return { ...state, activeEntry: { ...action.payload.entry }, entryEditorOpen: true };
    case actionTypes.CLOSE_ENTRY_EDITOR:
      return { ...state, activeEntry: {}, entryEditorOpen: false };
    case actionTypes.UPDATE_ENTRY:
      return { ...state, entries: { ...state.entries, [action.payload.date.toISODate()]: action.payload } };
    case actionTypes.SET_SELECTED_MONTH:
      return { ...state, selectedMonth: action.payload };
    case actionTypes.SET_UPDATE_AVAIL:
      return { ...state, updateAvailable: true, swReg: action.payload };
    case actionTypes.TOGGLE_LEFT_MENU:
      return { ...state, leftMenuOpen: !state.leftMenuOpen };
    case actionTypes.UPDATE_STATS:
      return { ...state, stats: { ...state.stats, ...action.payload } };
    case actionTypes.SET_USER_CONFIG:
      return { ...state, config: { ...action.payload } };
    case actionTypes.UPDATE_USER_CONFIG:
      return { ...state, config: { ...state.config, ...action.payload } };
    default:
      return state;
  }
};

export default reducer;
