import { call, put, takeEvery } from "redux-saga/effects";

import * as firebase from "../service/firestore";
import * as actionTypes from "./actionTypes";
import { DateTime } from "luxon";
function* generateStats(action) {
  //pull all entries from the month

  const startDate = DateTime.fromISO(action.payload).startOf("month");
  const endDate = startDate.endOf("month");

  const entries = yield call(firebase.getEntries, startDate, endDate);
  console.log("generating stats from", entries);
  let stats = { tpt: { count: 0, complete: 0 }, habits: {} };
  for (const [_, e] of Object.entries(entries)) {
    if (e.text) {
      stats.tpt.count++;
      if (e.complete) {
        stats.tpt.complete++;
      }
    }
    if (e.habits) {
      for (const h of e.habits) {
        stats.habits[h] = stats.habits[h] ? ++stats.habits[h] : 1;
      }
    }
  }

  yield call(firebase.saveStats, startDate.toISODate(), stats);
  yield put({ type: actionTypes.UPDATE_STATS, payload: { [startDate.toISODate()]: stats } });
  try {
  } catch (err) {}
}

function* uploadConfig(action) {
  yield console.log("Sending config to server", action.payload);
  try {
    yield call(firebase.setUserConfig, action.payload);
  } catch (err) {
    console.log("Error saving config", err);
  }
}

function* sagas() {
  yield takeEvery(actionTypes.GENERATE_STATS, generateStats);
  yield takeEvery(actionTypes.UPDATE_USER_CONFIG, uploadConfig);
}

export default sagas;
