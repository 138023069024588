import React from "react";

import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Button } from "@mui/material";
import DayCell from "../DayCell";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../store/actionTypes";
const daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const tableStyle = {
  "& td": {
    "&:hover": {
      background: "#b8b8b8",
    },
    cursor: "pointer",
    width: "14.2%",
    maxWidth: "14.2%",
    height: 100,
    maxHeight: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "top",
    padding: 1,
    borderBottomWidth: 0,
  },
  "& th": {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
};

const CalendarTable = (props) => {
  const dispatch = useDispatch();
  const mantra = useSelector((state) => state.config.mantra);
  const changeMonthBy = (diff) => {
    const newMonth = props.selectedMonth.plus({ months: diff });
    dispatch({ type: actionTypes.SET_SELECTED_MONTH, payload: newMonth });
  };
  return (
    <Table sx={tableStyle}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{ textAlign: "left" }}>
            <Button variant="text" onClick={() => changeMonthBy(-1)} sx={{ maxWidth: "20px", minWidth: "20px" }}>
              &lt;
            </Button>
            <Button variant="text" onClick={() => changeMonthBy(1)} sx={{ mr: 1, maxWidth: "20px", minWidth: "20px" }}>
              &gt;
            </Button>
            <Typography variant="h6" sx={{ display: "inline-block" }}>
              {props.selectedMonth.monthLong} {props.selectedMonth.year}
            </Typography>
          </TableCell>
          <TableCell colSpan={5} sx={{ textAlign: "right", fontStyle: "italic" }}>
            <Typography>{mantra}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          {daysOfTheWeek.map((d, i) => (
            <TableCell key={i} sx={{ textAlign: "center" }}>
              {d}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {props.items.map((w, wi) => {
          return (
            <TableRow key={wi}>
              {w.map((d, di) => (
                <DayCell
                  entry={d}
                  key={`${wi}-${di}`}
                  inSelectedMonth={d.date.month === props.selectedMonth.month}
                  habits={props.habits}
                />
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CalendarTable;
