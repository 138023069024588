import { lightBlue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#202125",
      paper: "#2a2b2f",
    },
    primary: {
      main: "#30a7ad",
      dark: "#13818d",
      light: "#44c2c7",
    },
  },
});

export default theme;
