import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import LoginPage from "./components/LoginPage";
import ScrollToTop from "./components/ScrollToTop";
import LoadingPage from "./components/LoadingPage";
// import ThemeConfig from "./theme";
// import GlobalStyles from "./theme/globalStyles";
import theme from "./darktheme";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        <Route element={<Dashboard />} path="/dashboard/*" />
        <Route element={<LoadingPage />} path="/load" />
        <Route element={<LoginPage />} path="/" />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
