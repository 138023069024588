import { Box } from "@mui/system";
import React from "react";
const HabitDot = (props) => {
  const style = {
    height: props.height || "100%",
    width: props.width || "100%",
    backgroundColor: "transparent",
    borderColor: props.color || "#e8e8e8",
    borderWidth: "thin",
    borderStyle: "solid",
    borderRadius: "50%",
    cursor: props.disabled ? "inherit" : "pointer",
    textAlign: "center",
    padding: "2%",
  };
  if (props.checked) {
    style.background = props.color || "#e8e8e8";
  }

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Box sx={style} onClick={handleClick}>
      {props.children}
    </Box>
  );
};

export default HabitDot;
