import { Avatar, Box, Checkbox, Grid, TableCell, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { green, red, amber, grey, deepOrange } from "@mui/material/colors";
import * as actionTypes from "../../../store/actionTypes";
import { DateTime } from "luxon";
import * as firebase from "../../../service/firestore";
import HabitDot from "../../HabitDot";

const DayCell = (props) => {
  const dispatch = useDispatch();
  const cellStyle = {
    background: grey[700],
  };

  if (!props.inSelectedMonth) {
    cellStyle.background = grey[800];
  }

  if (props.entry.complete) {
    cellStyle.background = green[400];
  }
  if (
    props.entry.text.length > 0 &&
    !props.entry.complete &&
    props.entry.date.toISODate() < DateTime.now().toISODate()
  ) {
    cellStyle.background = red[300];
  }

  const handleCompleteChange = (e) => {
    const newEntry = { ...props.entry };
    newEntry.complete = e.target.checked;
    dispatch({ type: actionTypes.UPDATE_ENTRY, payload: newEntry });
    firebase.saveEntry(newEntry.date.toISODate(), newEntry);
  };

  const dayLabelStyle =
    props.entry.date.toISODate() === DateTime.now().toISODate()
      ? { b: amber[200], t: grey[800], cn: "tour-step-2" }
      : { b: "transparent", t: "inhereit", cn: "" };
  return (
    <TableCell
      sx={cellStyle}
      className={dayLabelStyle.cn}
      onClick={() => {
        dispatch({ type: actionTypes.OPEN_ENTRY_EDITOR, payload: { entry: props.entry } });
      }}
    >
      <Box sx={{ position: "relative", overflow: "hidden", maxHeight: 80, textOverflow: "ellipsis" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", flexWrap: "nowrap" }}>
          <Box>
            {/* <Typography
              fontSize={16}
              sx={{
                minWidth: "20px",
                borderRadius: "50%",
                textAlign: "center",
                backgroundColor: dayLabelStyle.b,
                color: dayLabelStyle.t,
              }}
            > */}
            <Avatar sx={{ width: 20, height: 20, backgroundColor: dayLabelStyle.b }}>
              <Typography>{props.entry.date.day}</Typography>
            </Avatar>
            {/* </Typography> */}
          </Box>
          <Box>
            <Grid container spacing={1} pt="5px">
              {Object.keys(props.habits).map((k) => (
                <Grid item key={k}>
                  <HabitDot
                    width={12}
                    height={12}
                    checked={props.entry.habits.includes(k)}
                    color={props.habits[k].color}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* <Box>
            <Checkbox
              checked={props.entry.complete}
              onChange={handleCompleteChange}
              onClick={(e) => e.stopPropagation()}
              size="small"
              sx={{ p: 0, color: green[700], "&.Mui-checked": { color: green[700] } }}
              title="Completed"
            />
          </Box> */}
        </Box>

        <Typography style={{ fontSize: 14 }}>{props.entry.text}</Typography>
        <Box sx={{ position: "absolute", top: -5, right: -5, display: "none" }}></Box>
        <Box
          sx={{
            position: "absolute",
            top: 1,
            left: 100,
            display: "none",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
          }}
        ></Box>
      </Box>
    </TableCell>
  );
};

export default DayCell;
