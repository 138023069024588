import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import * as firebase from "../../service/firestore";
import HabitStatus from "./HabitStatus";
import { Link, useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EntryEditor = (props) => {
  const activeEntry = useSelector((state) => state.activeEntry);
  const entryEditorOpen = useSelector((state) => state.entryEditorOpen);
  const habits = useSelector((state) => state.habits);
  const [text, setText] = useState(activeEntry.text || "");
  const [complete, setComplete] = useState(activeEntry.complete || false);
  const [completeHabits, setCompleteHabits] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (activeEntry.date) {
      setText(activeEntry.text);
      setComplete(activeEntry.complete);
      setIsLocked(activeEntry.date.toISODate() < DateTime.now().plus({ days: -2 }).toISODate());
      setCompleteHabits(activeEntry.habits || []);
    }
  }, [entryEditorOpen]);

  //closes dialog if it's open when the path changes
  useEffect(() => {
    dispatch({ type: actionTypes.CLOSE_ENTRY_EDITOR });
  }, [pathname]);
  const handleSave = () => {
    const newEntry = {
      date: activeEntry.date,
      text: text.trim(),
      complete: complete,
      habits: completeHabits,
    };
    firebase.saveEntry(newEntry.date.toISODate(), newEntry);
    //do save
    dispatch({ type: actionTypes.UPDATE_ENTRY, payload: newEntry });
    //update stats
    dispatch({ type: actionTypes.GENERATE_STATS, payload: newEntry.date.toISODate() });

    dispatch({ type: actionTypes.CLOSE_ENTRY_EDITOR });
  };
  const handleCancel = () => {
    dispatch({ type: actionTypes.CLOSE_ENTRY_EDITOR });
  };
  const clearActiveEntry = () => {
    dispatch({ type: actionTypes.CLOSE_ENTRY_EDITOR });
  };
  const handleToggleHabitComplete = (id) => {
    const newHabitComp = [...completeHabits];
    const indexOf = newHabitComp.indexOf(id);
    if (indexOf >= 0) {
      newHabitComp.splice(indexOf, 1);
    } else {
      newHabitComp.push(id);
    }

    setCompleteHabits(newHabitComp);
  };
  if (!activeEntry.date) {
    return "";
  }
  return (
    <Dialog maxWidth="md" fullWidth open={entryEditorOpen} TransitionComponent={Transition} onClose={clearActiveEntry}>
      <DialogTitle>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item>{activeEntry.date.toLocaleString(DateTime.DATE_HUGE)}</Grid>
          <Grid item>
            <Checkbox checked={complete} onChange={(e) => setComplete(e.target.checked)} sx={{ p: 0 }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          variant="standard"
          placeholder="My top priority today is..."
          disabled={isLocked}
          helperText={isLocked ? "Time is up! This entry is locked because it is too old." : ""}
        ></TextField>
        <Grid container spacing={1} direction="column" sx={{ mt: 1 }}>
          {Object.keys(habits).map((k) => (
            <Grid item key={k}>
              <HabitStatus
                habit={habits[k]}
                complete={completeHabits.includes(k)}
                onClick={() => handleToggleHabitComplete(k)}
              />
            </Grid>
          ))}
        </Grid>
        {Object.keys(habits).length < 1 && (
          <Typography sx={{ fontStyle: "italic" }}>
            You don't have any daily habits. Set them <Link to="/dashboard/settings">here</Link>.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntryEditor;
