import React, { useEffect, useState } from "react";
import * as actionTypes from "../../store/actionTypes";
import * as firebase from "../../service/firestore";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "@firebase/auth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.uid);
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(firebase.auth, (user) => {
      if (user && user.uid && user.email) {
        dispatch({ type: actionTypes.LOGIN, payload: { email: user.email, uid: user.uid, photoURL: user.photoURL } });
      } else {
        setShowLogin(true);
      }
    });
  }, []);
  useEffect(() => {
    if (uid) {
      navigate("/load");
    }
  }, [uid]);

  // useEffect(()=>{
  //     console.log("Checking for auto login", firebase.auth.currentUser.uid)
  //     if(firebase.auth?.currentUser?.uid && firebase.auth?.currentUser?.email){
  //         dispatch({type:actionTypes.LOGIN, payload: {email: firebase.auth.currentUser.uid, uid: firebase.auth.currentUser.uid}})
  //     }
  // })

  const loginHandler = () => {
    firebase
      .signInWithGoogle()
      .then((resp) => {
        console.log("Return from login: ", resp);
        //   if(resp.user.email && resp.user.uid){
        //     dispatch({type:actionTypes.LOGIN, payload: {email: resp.user.email, uid:resp.user.uid}})
        //   }
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };
  return (
    <Grid container direction="column" justifyContent="center" sx={{ height: "100vh" }}>
      <Grid container item direction="row" justifyContent="center" spacing={3}>
        <Grid item xs={12} textAlign="center">
          <div sx={{ margin: "0 auto" }}>
            <img src={`${process.env.PUBLIC_URL}/images/android-chrome-192x192.png`} alt="top priority today" />
          </div>
          <Typography variant="h4">TopPriorityToday</Typography>
        </Grid>
        {showLogin && (
          <Grid item>
            <Button onClick={loginHandler} variant="outlined">
              <img src={process.env.PUBLIC_URL + "/images/google-icon.svg"} height={32} alt="google" />
              &nbsp; Sign In With Google
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LoginPage;
