export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_ENTRIES = "SET_ENTRIES";
export const APPEND_ENTIRES = "APPEND_ENTRIES";
export const OPEN_ENTRY_EDITOR = "OPEN_ENTRY_EDITOR";
export const CLOSE_ENTRY_EDITOR = "CLOSE_ENTRY_EDITOR";
export const UPDATE_ENTRY = "UPDATE_ENTRY";
export const SET_USER_CONFIG = "SET_USER_CONFIG";
export const UPDATE_USER_CONFIG = "UPDATE_USER_CONFIG";
export const SET_SELECTED_MONTH = "SET_SELECTED_MONTH";
export const SET_UPDATE_AVAIL = "SET_UPDATE_AVAIL";
export const TOGGLE_LEFT_MENU = "TOGGLE_LEFT_MENU";
export const SET_HABITS = "SET_HABITS";
export const GENERATE_STATS = "GENERATE_STATS";
export const UPDATE_STATS = "UPDATE_STATS";
