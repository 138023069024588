import { Drawer, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";
import InsightsPanel from "../../Dashboard/InsightsPanel";

const LeftMenu = (props) => {
  const leftMenuOpen = useSelector((state) => state.leftMenuOpen);
  return (
    <>
      <Drawer
        variant="permanent"
        open={leftMenuOpen}
        sx={{
          width: leftMenuOpen ? 240 : 0,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: leftMenuOpen ? 240 : 0, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Typography variant="h6" textAlign="center" sx={{ borderBottom: "1px solid white", mb: 2, pb: "10px" }}>
          Insights
        </Typography>
        {leftMenuOpen && <InsightsPanel isPanel />}
      </Drawer>
    </>
  );
};

export default LeftMenu;
