import { Grid, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HABIT_COLORS } from "../../utils/constants";
import HabitDot from "../HabitDot";
const HabitDotColorSelector = (props) => {
  return (
    <>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onCancel}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Grid container direction="row" spacing={1} sx={{ padding: 1 }}>
          {HABIT_COLORS.map((c, i) => (
            <Grid item key={i}>
              <HabitDot color={c} width={32} height={32} checked onClick={() => props.onSave(c)} />
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default HabitDotColorSelector;
