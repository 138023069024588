import { Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

const initData = {
  id: null,
  title: "Habit",
  complete: 0,
  inComplete: 0,
  color: green[200],
};

const HabitBar = (props) => {
  const perComplete =
    props.data.inComplete > 0 ? (props.data.complete / (props.data.complete + props.data.inComplete)) * 100 : 0;
  return (
    <>
      <Typography>{props.data.title}</Typography>
      <ResponsiveContainer width="100%" height={30}>
        <BarChart width={200} height={30} data={[props.data]} layout="vertical" stackOffset="expand">
          <Bar dataKey="complete" stackId="stack" fill={props.data.color} barSize={30} />
          <Bar dataKey="inComplete" stackId="stack" fill="#e8e8e8" />
          <XAxis hide type="number" />
          <YAxis type="category" dataKey="name" hide />
          <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1.5vh">
            {perComplete.toFixed(0)}%
          </text>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

HabitBar.defaultProps = {
  data: initData,
};
export default React.memo(HabitBar);
