import { Avatar, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import Footer from "../Dashboard/Footer";
import MobileFeed from "./MobileFeed";
import MobileInsights from "./MobileInsights";
import Tour from "./Tour";
const MobileView = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const tourComplete = window.localStorage.getItem("tourComplete");
  const handleChange = (e, v) => {
    setCurrentTab(v);
  };

  const Contents = () => {
    switch (currentTab) {
      case 0:
        return <MobileFeed />;
      case 1:
        return <MobileInsights />;
      default:
        return <>Unknown Tab</>;
    }
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {!tourComplete && <Tour />}
        <Box sx={{ mb: 1 }}>
          <Tabs value={currentTab} onChange={handleChange} centered>
            <Tab label="Priorities" />
            <Tab label="Insights" className="tour-step-insights" />
          </Tabs>
        </Box>
        <Contents />
      </Box>
    </>
  );
};

export default MobileView;
