import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserConfig } from "../../service/firestore";
import * as actionTypes from "../../store/actionTypes";

const LoadingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = useSelector((state) => state.uid);
  useEffect(() => {
    if (!uid) {
      navigate("/");
      return;
    }
    //load the data from firebase
    getUserConfig()
      .then((resp) => {
        console.log("load resp: ", resp);
        if (resp) {
          dispatch({ type: actionTypes.SET_USER_CONFIG, payload: resp });
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: actionTypes.LOGOUT });
      });
    //
  });
  return (
    <Grid container direction="column" justifyContent="center" sx={{ height: "100vh" }}>
      <Grid container item direction="row" justifyContent="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default LoadingPage;
