import { Grid } from "@mui/material";
import React from "react";
import HabitDot from "../../HabitDot";
const HabitStatus = (props) => {
  return (
    <Grid container item direction="row" wrap="nowrap" alignItems="center">
      <Grid item sx={{ mr: 1 }}>
        <HabitDot color={props.habit.color} checked={props.complete} height={24} width={24} onClick={props.onClick} />
      </Grid>
      <Grid flexGrow={1} onClick={props.onClick} sx={{ cursor: "pointer" }}>
        {props.habit.title}
      </Grid>
    </Grid>
  );
};

export default HabitStatus;
