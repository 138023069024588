import { Button, Grid, Radio, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import * as firebase from "../../../../../service/firestore";
import HabitDot from "../../../../HabitDot";
import { grey } from "@mui/material/colors";
import { HABIT_COLORS } from "../../../../../utils/constants";
import HabitDotColorSelector from "../../../../HabitDotColorSelector";
import EditIcon from "@mui/icons-material/Edit";
const Habit = (props) => {
  const [habit, setHabit] = useState({
    title: "",
    color: HABIT_COLORS[Math.random() * HABIT_COLORS.length],
    enabled: false,
    createdAt: DateTime.now().toISOTime(),
  });
  const habitHold = useRef({});
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [colorSelectorOpen, setColorSelectorOpen] = useState(false);
  const dotRef = useRef(null);

  useEffect(() => {
    if (props.habit) {
      setHabit({ ...props.habit });
    }
    if (props.id) {
      setId(props.id);
    }
  }, []);

  const handleTitleChange = (e) => {
    setHabit((state) => ({ ...state, title: e.target.value }));
  };
  const handleSave = async () => {
    setShowError(false);
    if (!habit.title || habit.title.trim().length < 1) {
      setShowError(true);
      return;
    }

    setIsEditing(false);
    firebase.updateHabit(id, habit);
  };
  const startEditing = () => {
    habitHold.current = habit;
    setIsEditing(true);
  };
  const handleCancel = () => {
    setHabit(habitHold.current);
    setIsEditing(false);
  };
  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      props.onDelete(id);
    }
  };
  const handleDotClick = () => {
    setColorSelectorOpen((state) => !state);
  };
  const handleColorSelectorCancel = () => {
    setColorSelectorOpen(false);
  };
  const handleColorSelectorSave = (newColor) => {
    setHabit({ ...habit, color: newColor });
    setColorSelectorOpen(false);
  };
  return (
    <Grid
      container
      item
      xs={12}
      wrap="nowrap"
      sx={{ backgroundColor: isEditing ? grey[700] : "transparent", padding: 2 }}
    >
      <Grid item xs={2} md={1} ref={dotRef}>
        <HabitDot width={32} height={32} color={habit.color} checked disabled={!isEditing} onClick={handleDotClick}>
          {isEditing && <EditIcon />}
        </HabitDot>
        <HabitDotColorSelector
          anchorEl={dotRef.current}
          open={colorSelectorOpen}
          onCancel={handleColorSelectorCancel}
          onSave={handleColorSelectorSave}
        />
      </Grid>
      <Grid item flexGrow={1}>
        {!isEditing && <Typography sx={{ pt: "4px" }}>{habit.title}</Typography>}
        {isEditing && (
          <TextField
            value={habit.title}
            onChange={handleTitleChange}
            fullWidth
            variant="standard"
            disabled={!isEditing}
            multiline
            error={showError}
            helperText={showError ? "Title is required" : ""}
          ></TextField>
        )}
      </Grid>
      <Grid item xs={4} md={2} textAlign="right">
        {!isEditing && (
          <>
            <Button onClick={startEditing}>Edit</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </>
        )}
        {isEditing && (
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Habit;
