import { Button, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState, useCallback } from "react";
import TPTGauge from "../../Charts/TPTGauge";
import HabitBar from "../../Charts/HabitBar";
import * as actionTypes from "../../../store/actionTypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as firebase from "../../../service/firestore";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom";
const initGaugeData = {
  complete: 0,
  inComplete: 0,
  missed: 0,
  possible: 0,
};

const InsightsPanel = (props) => {
  const month = useSelector((state) => state.selectedMonth);

  const [tptGaugeData, setTPTGaugeData] = useState(initGaugeData);
  const [habitData, setHabitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    const startDate = month.startOf("month");
    //1. Get Stats for the month
    const allStatData = await firebase.getStats(startDate, startDate); //only asking for one month
    const keys = Object.keys(allStatData);

    if (keys.length < 1) {
      setTPTGaugeData({ ...initGaugeData }); //reset - no stats
      setHabitData([]);
      return;
    }

    const statData = allStatData[keys[0]];
    const newGaugeData = {
      complete: statData.tpt.complete,
      inComplete: statData.tpt.count - statData.tpt.complete,
      missed: 0,
      possible: 0,
    };
    //generate possible tpts
    if (startDate > DateTime.now()) {
      //looking at future month
      newGaugeData.possible = 0;
    } else if (startDate.endOf("month") > DateTime.now()) {
      //looking at this month
      newGaugeData.possible = DateTime.now().day; //current day of the month
    } else {
      //looking at previous month
      newGaugeData.possible = startDate.daysInMonth;
    }

    newGaugeData.missed = newGaugeData.possible - newGaugeData.complete - newGaugeData.inComplete;
    newGaugeData.missed = newGaugeData.missed < 0 ? 0 : newGaugeData.missed; //bring back to zero. This can happen if someone fills in future days

    setTPTGaugeData(newGaugeData);

    //2. Get Habit Names
    const habitDetails = await firebase.getSelectHabitDetails(Object.keys(statData.habits));

    //3. Construct habit stats
    const newHabitData = [];
    for (const hKey in statData.habits) {
      newHabitData.push({
        id: hKey,
        complete: statData.habits[hKey],
        inComplete: newGaugeData.possible - statData.habits[hKey],
        title: habitDetails[hKey] ? habitDetails[hKey].title : "Habit",
        color: habitDetails[hKey] ? habitDetails[hKey].color : green[200],
      });
    }
    newHabitData.sort((a, b) => {
      return a.title < b.title ? -1 : 1;
    });
    setHabitData(newHabitData);
  }, [month]);

  useEffect(() => {
    loadData().catch(console.error);
  }, [loadData]);

  const changeMonthBy = (diff) => {
    const newMonth = month.plus({ months: diff });
    dispatch({ type: actionTypes.SET_SELECTED_MONTH, payload: newMonth });
  };
  return (
    <Grid container item direction="column" sx={{ height: "100%" }}>
      {props.showMonthSelector && (
        <Grid container item direction="row" justifyContent="space-around">
          <Typography variant="h6">
            <Button variant="text" onClick={() => changeMonthBy(-1)}>
              &lt;
            </Button>
            {month.monthLong} {month.year}
            <Button variant="text" onClick={() => changeMonthBy(1)}>
              &gt;
            </Button>
          </Typography>
        </Grid>
      )}

      <Grid item>
        <Typography textAlign="center" sx={{ mb: 1 }}>
          Priority Completion
        </Typography>
        <TPTGauge data={tptGaugeData} />
      </Grid>
      {habitData.length > 0 && (
        <Grid item sx={{ borderTop: "1px solid white", mt: 2, mb: 1 }}>
          <Typography variant="h6" textAlign="center" color="warning">
            Habits
          </Typography>
        </Grid>
      )}
      {habitData.map((h, i) => (
        <Grid item key={i} sx={{ pl: 2, pr: 2 }}>
          <HabitBar data={h} />
        </Grid>
      ))}
      <Grid item flexGrow={1}></Grid>
      {props.isPanel && (
        <Grid item justifySelf="flex-end">
          <Button fullWidth component={Link} to="/dashboard/settings">
            Settings
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

InsightsPanel.defaultProps = {
  isPanel: false,
};
export default InsightsPanel;
