import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
const steps = [
  {
    target: "body",
    content: "Welcome to TopPriorityToday. Take a quick tour to find out more.",
    disableBeacon: true,
    styles: { options: { arrowColor: "rgba(0,0,0,0)" } },
  },
  {
    target: ".tour-step-2",
    content: "Tap on the day to set your first Top Priority. Set your priority each day to reach your potential.",
  },
  {
    target: ".tour-step-3",
    content: "Tap your profile picture and head to settings to set your daily habits and your mantra.",
  },
  {
    target: ".tour-step-4",
    content: "Return to the main calendar by tapping on the TPT icon.",
  },
  {
    target: ".tour-step-insights",
    content: "Tap on INSIGHTS to check out your insights and analysis.",
  },
];
const Tour = () => {
  useEffect(() => {
    window.localStorage.setItem("tourComplete", true);
  }, []);

  //   return showTour &&
  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        run={true}
        scrollToFirstStep={true}
        disableOverlayClose={true}
        locale={{ last: "Get Started" }}
        styles={{
          options: {
            zIndex: 100000,
          },
        }}
      />
    </>
  );
};

export default Tour;
