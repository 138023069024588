import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FeedEntry from "../FeedEntry";
import * as actionTypes from "../../../store/actionTypes";
import * as firebase from "../../../service/firestore";
import { DateTime } from "luxon";

import InfiniteScroll from "react-infinite-scroll-component";

const MobileFeed = (props) => {
  const entries = useSelector((state) => state.entries);

  const [onScreenEntries, setOnScreenEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const entryDates = useSelector((state) => state.entryDates);
  const habits = useSelector((state) => state.habits);
  const dispatch = useDispatch();
  const initCall = useRef(false);

  useEffect(() => {
    if (!initCall.current) {
      return;
    }
    const newItems = loadItems();

    setOnScreenEntries(newItems);
  }, [entryDates, entries]);

  useEffect(() => {
    const endDate = DateTime.now().startOf("day").plus({ days: 1 });
    const startDate = endDate.plus({ days: -20 }); //history - we are going in reverse

    fetchEntries(startDate, endDate);
    initCall.current = true;
  }, []);

  const fetchEntries = async (startDate, endDate) => {
    setIsLoading(true);
    await firebase.getHabits(true).then((resp) => {
      dispatch({ type: actionTypes.SET_HABITS, payload: resp });
    });
    firebase
      .getEntries(startDate, endDate)
      .then((resp) => {
        if (resp) {
          //figure out our new start and end dates of entries
          const newStart = startDate < entryDates.start ? startDate : entryDates.start;
          const newEnd = endDate > entryDates.end ? endDate : entryDates.end;
          dispatch({
            type: actionTypes.APPEND_ENTIRES,
            payload: { entries: resp, entryDates: { start: newStart, end: newEnd } },
          });
        }
      })
      .catch((err) => {
        console.log("Error loading entires: ", err);
      })
      .finally(() => setIsLoading(false));
  };
  const loadItems = () => {
    let newItems = [];

    let currDay = entryDates.start;
    const endDate = DateTime.now().endOf("day").plus({ days: 1 }); //max out at today in mobile mode
    while (currDay <= endDate) {
      let entry = { date: currDay, complete: false, text: "", habits: [] };
      if (entries[currDay.toISODate()]) {
        let oldEntry = { ...entries[currDay.toISODate()] };
        if (oldEntry.date) {
          delete oldEntry.date;
        }
        entry = { ...entry, ...oldEntry };
      }
      newItems.push(entry);
      currDay = currDay.plus({ days: 1 });
    }

    return newItems;
  };

  const fetchMoreData = (additionaDays) => {
    const endDate = entryDates.start.plus({ days: -1 }); //go back in time
    const startDate = endDate.plus({ days: -10 });

    console.log("fetching more" + startDate.toISODate() + " " + endDate.toISODate());
    fetchEntries(startDate, endDate);
  };

  return (
    <Grid container direction="column-reverse" spacing={1} sx={{ pl: 1, pr: 1, overflow: "auto", flexGrow: 1 }}>
      {/* <InfiniteScroll
      dataLength={onScreenEntries.length}
      next={fetchMoreData}
      hasMore={true}
      // loader={<h4>Loading...</h4>}
      // scrollThreshold={0}
    > */}
      <Grid item>
        <Box textAlign="center">
          {isLoading && (
            <Box sx={{ m: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <Button variant="text" onClick={fetchMoreData}>
              Tap to load more
            </Button>
          )}
        </Box>
      </Grid>
      {onScreenEntries.map((e, i) => (
        <FeedEntry entry={e} key={i} habits={habits} />
      ))}

      {/* </InfiniteScroll> */}
    </Grid>
    // </div>
  );
};

export default MobileFeed;
