import { Campaign } from "@mui/icons-material";
import { Button, ClickAwayListener, Paper, Popper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

const MantraPopper = () => {
  const [open, setOpen] = useState(false);
  const mantra = useSelector((state) => state.config.mantra);

  const handleOpen = (e) => {
    setOpen((prev) => !prev);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  if (!mantra) {
    return <></>;
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ position: "fixed", bottom: 16, right: 16, textAlign: "right" }}>
        {open && <Paper sx={{ p: 2, m: 1 }}>{mantra || "Go to settings to set your mantra!"}</Paper>}
        <Button onClick={handleOpen} sx={{ borderRadius: 8 }} variant="contained">
          <Campaign />
        </Button>
      </Box>
    </ClickAwayListener>
  );
};

export default MantraPopper;
