import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  FieldPath,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut as fbSignOut } from "firebase/auth";
import { DateTime } from "luxon";
// const firebaseConfig = {
//   apiKey: "AIzaSyAhfj27QyD3UWNos8fUuDHrcGOQbnISSTs",
//   authDomain: "top-priority-today-dev.firebaseapp.com",
//   databaseURL: "https://top-priority-today-dev-default-rtdb.firebaseio.com",
//   projectId: "top-priority-today-dev",
//   storageBucket: "top-priority-today-dev.appspot.com",
//   messagingSenderId: "641111467800",
//   appId: "1:641111467800:web:bdb636815d54500c3da06d",
//   measurementId: "G-01SPFLYBGQ",
// };
const firebaseConfig = {
  apiKey: "AIzaSyBBEvj96scKbR87POB5fgJOJRSIKVOfXgU",
  authDomain: "app.topprioritytoday.com",
  databaseURL: "https://top-priority-today-default-rtdb.firebaseio.com",
  projectId: "top-priority-today",
  storageBucket: "top-priority-today.appspot.com",
  messagingSenderId: "44250765357",
  appId: "1:44250765357:web:38e4ce8c0077487df640a4",
  measurementId: "G-E2H068BV6Y",
};

export const fbapp = initializeApp(firebaseConfig);
export const auth = getAuth(fbapp);
const db = getFirestore(fbapp);
const authId = () => {
  return auth.currentUser.email.replace(".", "_");
};

// console.log(auth)
const provider = new GoogleAuthProvider(auth);
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};
export const signOut = () => fbSignOut(auth);

export const getUserConfig = async () => {
  const snapshot = await getDoc(doc(db, "user-data", authId()));
  if (snapshot.exists()) {
    const config = snapshot.get("config");
    return config ? config : {};
  } else {
    return {};
  }
};
export const setUserConfig = async (newConfig) => {
  return await updateDoc(doc(db, "user-data", authId()), { config: newConfig });
};

export const getEntries = async (startDate, endDate) => {
  const q = query(
    collection(db, "user-data", authId(), "entries"),
    where("__name__", ">=", startDate.toISODate()),
    where("__name__", "<=", endDate.toISODate())
  );
  const snapshot = await getDocs(q);

  const output = {};
  snapshot.forEach((e) => {
    output[e.id] = e.data();
  });
  return output;
};

export const saveEntry = (entryDate, entry) => {
  //const dbRef = ref(db, 'user-data/' + authId() + '/entries')
  // return push(dbRef, entry)
  const newEntry = { ...entry };
  if (newEntry.date && DateTime.isDateTime(newEntry.date)) {
    newEntry.date = newEntry.date.toISODate();
  }
  return setDoc(doc(collection(db, "user-data", authId(), "entries"), entryDate), newEntry);
  //return set(ref(db, "user-data/" + authId() + "/entries/" + entryDate), entry);
};

/*********** habits */
export const createHabit = async (habit) => {
  const newDocRef = doc(collection(db, "user-data", authId(), "habits"));

  await setDoc(newDocRef, habit);
  return newDocRef.id;
};
export const getHabits = async (enabledOnly = true) => {
  const q = query(collection(db, "user-data", authId(), "habits"), where("enabled", "==", true));
  const snapshot = await getDocs(q);

  const output = {};
  snapshot.forEach((e) => {
    output[e.id] = e.data();
  });

  return output;
};
export const updateHabit = async (id, habit) => {
  const newDocRef = doc(collection(db, "user-data", authId(), "habits"), id);

  await setDoc(newDocRef, habit);
  return true;
};
export const deleteHabit = async (id) => {
  const ref = doc(collection(db, "user-data", authId(), "habits"), id);
  await setDoc(ref, { enabled: false }, { merge: true });
};
export const getSelectHabitDetails = async (habitIDs) => {
  const q = query(collection(db, "user-data", authId(), "habits"), where("__name__", "in", habitIDs));
  const snapshot = await getDocs(q);

  const output = {};
  snapshot.forEach((e) => {
    output[e.id] = e.data();
  });

  return output;
};
/**************stats **************/
export const saveStats = async (statDate, stats) => {
  return setDoc(doc(collection(db, "user-data", authId(), "stats"), statDate), stats);
};
export const getStats = async (startDate, endDate) => {
  const q = query(
    collection(db, "user-data", authId(), "stats"),
    where("__name__", ">=", startDate.toISODate()),
    where("__name__", "<=", endDate.toISODate())
  );
  const snapshot = await getDocs(q);

  const output = {};
  snapshot.forEach((e) => {
    output[e.id] = e.data();
  });

  return output;
};

export const cloneUserData = async () => {
  const collections = ["entries", "stats", "habits"];

  collections.forEach(async (c) => {
    const docs = await getDocs(collection(db, "user-data", auth.currentUser.uid, c));
    docs.forEach(async (d) => {
      await setDoc(doc(collection(db, "user-data", authId(), c), d.id), d.data());
    });
  });
};
