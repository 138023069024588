import React, { memo, useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import DayCell from "./DayCell";
import { useDispatch, useSelector } from "react-redux";
import CalendarTable from "./CalendarTable";
import CalendarTableSkeleton from "./CalendarTableSkeleton";
import * as firebase from "../../service/firestore";
import * as actionTypes from "../../store/actionTypes";
import LeftMenu from "./LeftMenu";
import Footer from "../Dashboard/Footer";
import Tour from "./Tour";

const DesktopView = (props) => {
  const entries = useSelector((state) => state.entries);
  const mantra = useSelector((state) => state.config.mantra);
  const selectedMonth = useSelector((state) => state.selectedMonth);
  const [isLoading, setIsLoading] = useState(true);
  const entryDates = useSelector((state) => state.entryDates);
  const habits = useSelector((state) => state.habits);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const tourComplete = window.localStorage.getItem("tourComplete");
  let startDate = null;
  let endDate = null;

  useEffect(() => {
    startDate = selectedMonth.startOf("week").plus({ days: -1 });
    endDate = selectedMonth.endOf("month").endOf("week").plus({ days: -1 });

    if (entryDates.start.toISODate() === startDate.toISODate() && entryDates.end.toISODate() === endDate.toISODate()) {
      setItems(loadItems(startDate, endDate));
      setIsLoading(false);
    } else {
      setIsLoading(true);

      fetchEntries(startDate, endDate)
        .then((resp) => {
          if (resp) {
            dispatch({
              type: actionTypes.SET_ENTRIES,
              payload: { entries: resp, entryDates: { start: startDate, end: endDate } },
            });
          }
        })
        .catch((err) => {
          console.log("Error loading entires: ", err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedMonth, entries]);

  const fetchEntries = async (startDate, endDate) => {
    await firebase.getHabits(true).then((resp) => {
      dispatch({ type: actionTypes.SET_HABITS, payload: resp });
    });

    return await firebase.getEntries(startDate, endDate);
  };

  const loadItems = (startDate, endDate) => {
    let newItems = [];
    let weekArr = [];
    let currDay = startDate;
    while (currDay <= endDate) {
      let entry = { date: currDay, complete: false, text: "", habits: [] };
      if (entries[currDay.toISODate()]) {
        let oldEntry = { ...entries[currDay.toISODate()] };
        if (oldEntry.date) {
          delete oldEntry.date;
        }

        entry = { ...entry, ...oldEntry };
      }
      weekArr.push(entry);
      if (weekArr.length % 7 === 0) {
        newItems.push(weekArr);
        weekArr = [];
      }
      currDay = currDay.plus({ days: 1 });
    }

    return newItems;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <LeftMenu />
      <Box sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <CalendarTableSkeleton />
        ) : (
          <>
            <CalendarTable selectedMonth={selectedMonth} items={items} habits={habits} />
            {!tourComplete && <Tour />}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DesktopView;
