import { Button, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import { DateTime } from "luxon";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as firebase from "../../../../service/firestore";
import Habit from "./Habit";
import * as actionTypes from "../../../../store/actionTypes";
import { HABIT_COLORS } from "../../../../utils/constants";

const HabitSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const habits = useSelector((state) => state.habits);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    firebase
      .getHabits(true)
      .then((resp) => {
        dispatch({ type: actionTypes.SET_HABITS, payload: resp });
      })
      .catch((err) => {
        console.log("Error getting habits: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleNewHabit = () => {
    const newColor = HABIT_COLORS[Math.floor(Math.random() * HABIT_COLORS.length)];
    const newHabit = { enabled: true, color: newColor, title: "My New Habit" };

    firebase.createHabit(newHabit).then((hid) => {
      console.log("adding:", hid);
      dispatch({ type: actionTypes.SET_HABITS, payload: { ...habits, [hid]: newHabit } });
    });
  };
  const handleDelete = (id) => {
    firebase.deleteHabit(id).then((resp) => {
      let newHabitList = { ...habits };
      delete newHabitList[id];
      dispatch({ type: actionTypes.SET_HABITS, payload: newHabitList });
    });
  };
  return (
    <Paper sx={{ border: "1px solid #e8e8e8", padding: 2, width: "100%", maxWidth: "md", m: 1 }}>
      <Grid container justifyContent="space-between" spacing={2} sx={{ width: "100%", m: 1 }}>
        <Grid item>
          <Typography variant="h5">Daily Habits</Typography>
        </Grid>
        <Grid item>
          <Button onClick={handleNewHabit} variant="contained" disabled={Object.keys(habits).length >= 3}>
            + New Habit
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {!isLoading &&
          Object.keys(habits).map((k) => (
            <Grid item xs={12} key={k}>
              <Habit habit={habits[k]} id={k} onDelete={handleDelete} />
            </Grid>
          ))}
        {isLoading && <CircularProgress sx={{ margin: "5px auto" }} />}
      </Grid>
    </Paper>
  );
};

export default HabitSettings;
